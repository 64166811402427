import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface S3ApiResponse extends Array<S3ApiObject> {}

interface S3ApiObject {
  Key: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(private http: HttpClient) {}

  // See sample-directories.json
  getVersions() {
    return this.http.get<S3ApiResponse>('/directories.json');
  }
}
