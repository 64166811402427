import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginGuard } from './login/login.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: '',
    component: HomeComponent,
    data: { animation: 'home', title: 'Home' },
    canActivate: [LoginGuard]
  },
  {
    path: 'alert',
    loadChildren: () => import('./alert/alert.module').then(m => m.AlertModule),
    data: { animation: 'alert', title: 'Alert' },
    canActivate: [LoginGuard]
  },
  {
    path: 'icon',
    loadChildren: () => import('./icon/icon.module').then(m => m.IconModule),
    data: { animation: 'icon', title: 'Icon' },
    canActivate: [LoginGuard]
  },
  {
    path: 'typography',
    loadChildren: () => import('./typography/typography.module').then(m => m.TypographyModule),
    data: { animation: 'typography', title: 'Typography' },
    canActivate: [LoginGuard]
  },
  {
    path: 'spacing',
    loadChildren: () => import('./spacing/spacing.module').then(m => m.SpacingModule),
    data: { animation: 'spacing', title: 'Spacing' },
    canActivate: [LoginGuard]
  },
  {
    path: 'card',
    loadChildren: () => import('./card/card.module').then(m => m.CardModule),
    data: { animation: 'card', title: 'Card' },
    canActivate: [LoginGuard]
  },
  {
    path: 'button',
    loadChildren: () => import('./button/button.module').then(m => m.ButtonModule),
    data: { animation: 'button', title: 'Button' },
    canActivate: [LoginGuard]
  },
  {
    path: 'collapse',
    loadChildren: () => import('./collapse/collapse.module').then(m => m.CollapseModule),
    data: { animation: 'collapse', title: 'Collapse' },
    canActivate: [LoginGuard]
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then(m => m.ListModule),
    data: { animation: 'list', title: 'List' },
    canActivate: [LoginGuard]
  },
  {
    path: 'table',
    loadChildren: () => import('./table/table.module').then(m => m.TableModule),
    data: { animation: 'table', title: 'Table' },
    canActivate: [LoginGuard]
  },
  {
    path: 'tooltip',
    loadChildren: () => import('./tooltip/tooltip.module').then(m => m.TooltipModule),
    data: { animation: 'tooltip', title: 'Tooltip' },
    canActivate: [LoginGuard]
  },
  {
    path: 'form',
    loadChildren: () => import('./form/form.module').then(m => m.FormModule),
    data: { animation: 'form', title: 'Form' },
    canActivate: [LoginGuard]
  },
  {
    path: 'border',
    loadChildren: () => import('./border/border.module').then(m => m.BorderModule),
    data: { animation: 'border', title: 'Border' },
    canActivate: [LoginGuard]
  },
  {
    path: 'layout',
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
    data: { animation: 'layout', title: 'Layout' },
    canActivate: [LoginGuard]
  },
  {
    path: 'number-counter',
    loadChildren: () => import('./number-counter/number-counter.module').then(m => m.NumberCounterModule),
    data: { animation: 'number-counter', title: 'Number counter' },
    canActivate: [LoginGuard]
  },
  {
    path: 'background',
    loadChildren: () => import('./background/background.module').then(m => m.BackgroundModule),
    data: { animation: 'background', title: 'Background' },
    canActivate: [LoginGuard]
  },
  {
    path: 'progress-line',
    loadChildren: () => import('./progress-line/progress-line.module').then(m => m.ProgressLineModule),
    data: { animation: 'progress-line', title: 'Progress Line' },
    canActivate: [LoginGuard]
  },
  {
    path: 'badge',
    loadChildren: () => import('./badge/badge.module').then(m => m.BadgeModule),
    data: { animation: 'badge', title: 'Badge' },
    canActivate: [LoginGuard]
  },
  {
    path: 'timeline',
    loadChildren: () => import('./timeline/timeline.module').then(m => m.TimelineModule),
    data: { animation: 'timeline', title: 'Timeline' },
    canActivate: [LoginGuard]
  },
  {
    path: 'logo',
    loadChildren: () => import('./logo/logo.module').then(m => m.LogoModule),
    data: { animation: 'logo', title: 'Logo' },
    canActivate: [LoginGuard]
  },
  {
    path: 'pagination',
    loadChildren: () => import('./pagination/pagination.module').then(m => m.PaginationModule),
    data: { animation: 'pagination', title: 'Pagination' },
    canActivate: [LoginGuard]
  },
  {
    path: 'misc',
    loadChildren: () => import('./misc/misc.module').then(m => m.MiscModule),
    data: { animation: 'misc', title: 'Miscellaneous' },
    canActivate: [LoginGuard]
  },
  {
    path: 'datepicker',
    loadChildren: () => import('./datepicker/datepicker.module').then(m => m.DatepickerModule),
    data: { animation: 'datepicker', title: 'Datepicker' },
    canActivate: [LoginGuard]
  },
  {
    path: 'animation',
    loadChildren: () => import('./animation/animation.module').then(m => m.AnimationModule),
    data: { animation: 'animation', title: 'Animation' },
    canActivate: [LoginGuard]
  },
  {
    path: 'range-slider',
    loadChildren: () => import('./range-slider/range-slider.module').then(m => m.RangeSliderModule),
    data: { animation: 'range-slider', title: 'Range slider' },
    canActivate: [LoginGuard]
  },
  {
    path: 'header',
    loadChildren: () => import('./header/header.module').then(m => m.HeaderModule),
    data: { animation: 'header', title: 'Header' },
    canActivate: [LoginGuard]
  },
  {
    path: 'changelog',
    loadChildren: () => import('./changelog/changelog.module').then(m => m.ChangelogModule),
    data: { animation: 'changelog', title: 'Change log' },
    canActivate: [LoginGuard]
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
