import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ThemeService } from '../theme.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  theme: Observable<any>;
  themeLogo: Observable<any>;

  constructor(private themeService: ThemeService) {}

  ngOnInit() {
    this.theme = this.themeService.selectedTheme.asObservable();
    this.themeLogo = this.themeService.selectedThemeLogo.asObservable();
  }
}
