import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Button } from './button.interface';

@Component({
  selector: 'msp-button',
  templateUrl: './button.component.html'
})
export class ButtonComponent {
  @Input() config: Button = {
    type: 'basic',
    action: 'button',
    progressType: 'bar'
  };

  @Input() inProgress = false;
  @Input() disabled = false;
  @Output() mspClick: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  handleClick() {
    this.mspClick.emit();
  }
}
