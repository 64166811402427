<div class="msp-button">
  <button
    *ngIf="config.type === 'basic'"
    mat-button
    color="{{ config.color }}"
    [type]="config.action"
    [disabled]="inProgress || disabled"
    [attr.aria-label]="config.ariaLabel"
    [attr.aria-haspopup]="config.ariaHaspopup"
    class="button"
    (click)="handleClick()"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
  <button
    *ngIf="config.type === 'flat'"
    mat-flat-button
    color="{{ config.color }}"
    [type]="config.action"
    [disabled]="inProgress || disabled"
    [attr.aria-label]="config.ariaLabel"
    [attr.aria-haspopup]="config.ariaHaspopup"
    class="button"
    (click)="handleClick()"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
  <button
    *ngIf="config.type === 'outline'"
    mat-stroked-button
    color="{{ config.color }}"
    [type]="config.action"
    [disabled]="inProgress || disabled"
    [attr.aria-label]="config.ariaLabel"
    [attr.aria-haspopup]="config.ariaHaspopup"
    class="button"
    (click)="handleClick()"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>

  <ng-template #content>
    <mat-spinner
      *ngIf="inProgress && config.progressType === 'spinner'"
      [diameter]="20"
      color="{{ config.color }}"
      class="progress-spinner"
      aria-label="Progress spinner"
    ></mat-spinner>
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="inProgress && config.progressType !== 'spinner'"
      color="{{ config.color }}"
      class="progress-bar"
      aria-label="Progress bar"
    ></mat-progress-bar>
    <ng-content></ng-content>
  </ng-template>
</div>
