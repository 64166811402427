import { Injectable } from '@angular/core';
import { UrlTree, CanActivate, CanActivateChild, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.activateOrRedirect();
  }

  canActivateChild(): Observable<boolean | UrlTree> {
    return this.activateOrRedirect();
  }

  private activateOrRedirect() {
    if (environment.password) {
      const sessionKey = sessionStorage.getItem('key');
      if (sessionKey === 'westpac51') {
        return of(true);
      } else {
        return of(this.router.parseUrl('/login'));
      }
    } else {
      return of(true);
    }
  }
}
