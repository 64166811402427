import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { each } from 'lodash';
import { slideInAnimation } from './app-routing.animation';
import { environment } from '../environments/environment';
import { version } from '../../../shared-components/package.json';
import { AppService } from './app.service';
import { APP_BASE_HREF } from '@angular/common';
import * as semver from 'semver';
import { ThemeService } from './theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent implements OnInit {
  @ViewChild('drawer', { static: true }) drawer: MatSidenav;
  isMultiversion = environment.is_multiversion;
  versions;
  themes = [
    { name: 'mx51', value: 'mx51' },
    { name: 'Westpac', value: 'westpac' },
    { name: 'Commerce Bank', value: 'commercebank' },
    { name: 'Till', value: 'till' },
    { name: 'Gecko', value: 'gecko' }
  ];
  selectedTheme = 'mx51';
  selectedVersion;
  environmentTheme = '';
  showThemeDropdown = true;
  isLoginScreen = true;
  sharedComponentVersion = '';
  isTablet$: Observable<boolean> = this.breakpointObserver
    .observe(['(max-width: 960px)'])
    .pipe(map(result => result.matches));

  navItems = [
    {
      groupTitle: 'Components',
      groupIcon: 'icon-statement',
      nav: [
        { title: 'Alert', route: '/alert' },
        { title: 'Button', route: '/button' },
        { title: 'Card', route: '/card' },
        { title: 'Collapse', route: '/collapse' },
        { title: 'Datepicker', route: '/datepicker' },
        { title: 'Form', route: '/form' },
        { title: 'Icon', route: '/icon' },
        { title: 'Number counter', route: '/number-counter' },
        { title: 'Pagination', route: '/pagination' },
        { title: 'Progress line', route: '/progress-line' },
        { title: 'Range slider', route: '/range-slider' },
        { title: 'Tooltip', route: '/tooltip' }
      ]
    },
    {
      groupTitle: 'Utilities',
      groupIcon: 'icon-stack',
      nav: [
        { title: 'Animation', route: '/animation' },
        { title: 'Badge', route: '/badge' },
        { title: 'Border', route: '/border' },
        { title: 'Background', route: '/background' },
        { title: 'Header', route: '/header' },
        { title: 'Layout', route: '/layout' },
        { title: 'List', route: '/list' },
        { title: 'Logo', route: '/logo' },
        { title: 'Miscellaneous', route: '/misc' },
        { title: 'Spacing', route: '/spacing' },
        { title: 'Table', route: '/table' },
        { title: 'Timeline', route: '/timeline' },
        { title: 'Typography', route: '/typography' }
      ]
    }
  ];

  constructor(
    public router: Router,
    private breakpointObserver: BreakpointObserver,
    private appService: AppService,
    private themeService: ThemeService,
    @Inject(APP_BASE_HREF) public baseHref: string
  ) {}

  ngOnInit(): void {
    this.sharedComponentVersion = version;
    this.environmentTheme = environment.theme;
    this.showThemeDropdown = environment.showThemeDropdown;

    if (this.showThemeDropdown && localStorage.getItem('theme') !== null) {
      this.selectedTheme = 'mx51';
    } else {
      this.selectedTheme = this.environmentTheme;
    }

    this.updateTheme();

    this.isTablet$.subscribe(val => {
      if (val) {
        this.drawer.close();
      }
    });

    if (this.isMultiversion) {
      this.getVersions();

      this.selectedVersion = this.baseHref.replace(/\//g, '');
    }
  }

  resizeWindow(event): void {
    window.dispatchEvent(new Event('resize'));
  }

  updateTheme(): void {
    const existingStylesheet = document.head.querySelectorAll(`link[rel="stylesheet"].theme`);

    each(existingStylesheet, stylesheet => {
      document.head.removeChild(stylesheet);
    });

    const linkEl = document.createElement('link');
    linkEl.id = `${this.selectedTheme}-theme`;
    linkEl.rel = 'stylesheet';
    linkEl.href = `${this.selectedTheme}.css?v=${Math.random()}`;
    linkEl.classList.add('theme');

    document.head.appendChild(linkEl);

    this.themeService.updateTheme(this.selectedTheme);
  }

  onNavigate() {
    this.isLoginScreen = this.router.url === '/login';
    document.getElementsByTagName('mat-sidenav-content')[0].scrollTo(0, 0);
    this.isTablet$.subscribe(val => {
      if (val) {
        this.drawer.close();
      }
    });
  }

  updateVersion(selectChangeEvent) {
    this.selectedVersion = selectChangeEvent.value;
    window.location.href = `/${this.selectedVersion}`;
  }

  getVersions() {
    this.appService.getVersions().subscribe(
      response =>
        (this.versions = response
          .map(e => e.Key.replace(/\//g, ''))
          .sort(this.recentToOldestSemver)
          .map((e, index) => {
            return { value: e, isLatest: index === 0 };
          }))
    );
  }

  recentToOldestSemver(a, b) {
    if (semver.gt(a, b)) return -1;
    if (!semver.gt(a, b)) return 1;
    return 0;
  }
}
