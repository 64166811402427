import { Component, ViewChild, OnInit, OnDestroy, Input } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-slides',
  templateUrl: './slides.component.html',
  styleUrls: ['./slides.component.scss']
})
export class SlidesComponent implements OnInit, OnDestroy {
  @Input() theme: string;
  @ViewChild('slideGroup', { static: true }) tabGroup: MatTabGroup;

  tabLength: number;
  interval = 13500;
  tabIndex = 0;
  timer: any;

  slideConfig = {
    mx51: ['assets/mx51/libs-01.png', 'assets/mx51/libs-02.png', 'assets/mx51/libs-03.png', 'assets/mx51/libs-04.png'],
    westpac: [
      'assets/westpac/westpac-01.png',
      'assets/westpac/westpac-02.png',
      'assets/westpac/westpac-03.png',
      'assets/westpac/westpac-04.png'
    ],
    commercebank: [
      'assets/commerce/commerce-01.png',
      'assets/commerce/commerce-02.png',
      'assets/commerce/commerce-03.png',
      'assets/commerce/commerce-04.png'
    ],
    till: ['assets/mx51/libs-01.png', 'assets/mx51/libs-02.png', 'assets/mx51/libs-03.png', 'assets/mx51/libs-04.png'],
    gecko: ['assets/mx51/libs-01.png', 'assets/mx51/libs-02.png', 'assets/mx51/libs-03.png', 'assets/mx51/libs-04.png']
  };

  constructor() {}

  ngOnInit() {
    this.tabLength = this.slideConfig[this.theme].length;
    this.timer = setInterval(() => {
      if (this.tabIndex === this.tabLength - 1) {
        this.tabIndex = 0;
      } else {
        this.tabIndex++;
      }
    }, this.interval);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  updateTabIndex(index: number) {
    this.tabIndex = index;
  }
}
