import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  selectedTheme = new BehaviorSubject<string>('mx51');
  selectedThemeLogo = new BehaviorSubject<any>({ name: `icon-logo-mx51}`, size: 'large' });

  updateTheme(selectedTheme: string) {
    this.selectedTheme.next(selectedTheme);
    this.selectedThemeLogo.next({ name: `icon-logo-${selectedTheme}`, size: 'auto' });
  }
}
