<main>
  <mat-sidenav-container class="shared-components-guide-container mat-sidenav-container-alt" fullscreen>
    <mat-sidenav
      #drawer
      fixedInViewport="true"
      [attr.role]="(isTablet$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isTablet$ | async) ? 'over' : 'side'"
      [opened]="(isTablet$ | async) ? 'false' : 'true'"
      [ngClass]="{ 'd-none': isLoginScreen }"
    >
      <div class="border-bottom">
        <div class="width-xs-100 mx-auto">
          <a routerLink="/">
            <msp-icon [config]="{ name: 'icon-logo-mx51-white' }"></msp-icon>
          </a>
        </div>
      </div>
      <div class="pb-20">
        <div *ngIf="showThemeDropdown" class="px-10 mt-20">
          <mat-form-field appearance="outline" class="mb-0">
            <mat-select [(value)]="selectedTheme" (selectionChange)="updateTheme()">
              <mat-option *ngFor="let theme of themes" [value]="theme.value">
                {{ theme.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="isMultiversion" class="px-10 mt-20">
          <mat-form-field appearance="outline" class="mb-0">
            <mat-select [(value)]="selectedVersion" (selectionChange)="updateVersion($event)">
              <mat-option *ngFor="let version of versions" [value]="version.value">
                v{{ version.value }} {{ version.isLatest ? '(latest)' : '' }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <ng-container *ngFor="let navItem of navItems">
          <h3 class="library-heading my-15 mx-10 d-flex justify-content-start">
            <msp-icon
              [config]="{ name: navItem.groupIcon, size: 'xsmall' }"
              class="d-flex align-items-center mr-10"
            ></msp-icon>
            <span>{{ navItem.groupTitle }}</span>
          </h3>
          <ul class="list-unstyled">
            <li *ngFor="let nav of navItem.nav" [ngClass]="{ 'nav-active': router.url === nav.route }">
              <a routerLink="{{ nav.route }}" class="d-flex align-items-center">
                <msp-icon
                  [config]="{ name: 'icon-chevron-right', size: 'small' }"
                  class="d-flex align-items-center"
                  [ngClass]="{ invisible: router.url !== nav.route }"
                ></msp-icon>
                <span>{{ nav.title }}</span>
              </a>
            </li>
          </ul>
        </ng-container>

        <div class="p-10 mt-35 border-top d-flex justify-content-between align-items-center">
          <a routerLink="/changelog" class="type-size-12">
            <!-- <msp-icon [config]="{ name: 'icon-chevron-right', size: 'xsmall' }" class="d-flex align-items-center mx-5"></msp-icon> -->
            Change log
          </a>
          <div class="type-size-12" *ngIf="!isMultiversion">v{{ sharedComponentVersion }}</div>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div *ngIf="!isLoginScreen" class="content-header d-flex align-items-center">
        <div class="width-xs-50 mr-15">
          <a routerLink="/"><msp-icon [config]="{ name: 'icon-logo-assembly-white', size: 'auto' }"></msp-icon></a>
        </div>
        <span class="type-size-24">{{ o.activatedRouteData['title'] }}</span>
        <msp-button
          [config]="{ type: 'flat', action: 'button' }"
          class="button-icon-round ml-auto"
          (click)="drawer.open()"
        >
          <msp-icon [config]="{ name: 'icon-menu', size: 'small' }"></msp-icon>
        </msp-button>
      </div>
      <div
        class="mx-auto theme-{{ selectedTheme }}"
        [ngClass]="{ 'py-30': router.url !== '/' }"
        [@slideInAnimation]="o && o.activatedRouteData && o.activatedRouteData['animation']"
      >
        <router-outlet #o="outlet" (activate)="onNavigate()"></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</main>
<footer></footer>
