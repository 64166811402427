export const iconList = [
  // icon social
  'icon-social-facebook',
  'icon-social-instagram',
  'icon-social-linkedin',
  'icon-social-twitter',
  'icon-social-share',
  'icon-social-url',

  // icon status
  'icon-cross',
  'icon-cross-full',
  'icon-error',
  'icon-info',
  'icon-tick',
  'icon-tick-full',
  'icon-warning',
  'icon-dollar-circle',
  'icon-success',
  'icon-failure',

  // icon chevron
  'icon-chevron-right',
  'icon-chevron-left',
  'icon-chevron-up',
  'icon-chevron-down',
  'icon-circle-chevron-right',
  'icon-circle-chevron-left',
  'icon-circle-chevron-up',
  'icon-circle-chevron-down',

  // icon arrow
  'icon-arrow-right',
  'icon-arrow-left',
  'icon-arrow-up',
  'icon-arrow-down',

  // icon logo
  'icon-logo-assembly',
  'icon-logo-assembly-white',
  'icon-logo-mx51',
  'icon-logo-mx51-alt',
  'icon-logo-mx51-white',
  'icon-logo-westpac',
  'icon-logo-westpac-alt',
  'icon-logo-stgeorge',
  'icon-logo-bpay',
  'icon-logo-payid',
  'icon-logo-amex',
  'icon-logo-amex-alt',
  'icon-logo-visa',
  'icon-logo-visa-alt',
  'icon-logo-mastercard',
  'icon-logo-mastercard-alt',
  'icon-logo-diners-club',
  'icon-logo-diners-club-alt',
  'icon-logo-jcb',
  'icon-logo-jcb-alt',
  'icon-logo-eftpos',
  'icon-logo-unionpay',
  'icon-logo-discover',
  'icon-logo-cvv',
  'icon-logo-cip',
  'icon-logo-zip',
  'icon-logo-zip-alt',
  'icon-card-unknown',
  'icon-logo-abacus',
  'icon-logo-till',
  'icon-logo-gecko',

  // icon misc
  'icon-battery-charge',
  'icon-add',
  'icon-add-circle',
  'icon-ellipsis',
  'icon-search',
  'icon-barchart',
  'icon-checklist',
  'icon-content',
  'icon-document-middle',
  'icon-download',
  'icon-download-alt',
  'icon-upload',
  'icon-image-upload',
  'icon-stack',
  'icon-statement',
  'icon-bin-full',
  'icon-copy',
  'icon-copy-full',
  'icon-menu',
  'icon-star',
  'icon-star-full',
  'icon-view-card',
  'icon-view-list',
  'icon-terminal',
  'icon-terminal-battery',
  'icon-terminal-signal',
  'icon-terminal-sync',

  'icon-key',
  'icon-building',
  'icon-cart',
  'icon-menu-user',
  'icon-menu-user-alt',
  'icon-settings',
  'icon-org',
  'icon-signout',
  'icon-signout-alt',
  'icon-edit',
  'icon-edit-alt',
  'icon-trash',
  'icon-trash-alt',
  'icon-resend',
  'icon-merchant',
  'icon-user-group',
  'icon-api',
  'icon-online',
  'icon-instore',
  'icon-phone',
  'icon-people-standard',
  'icon-shop',
  'icon-shopping-trolley',
  'icon-suitcase',

  // icon nav
  'icon-nav-dashboard',
  'icon-nav-organisations',
  'icon-nav-sales',
  'icon-nav-merchant',
  'icon-nav-terminal',
  'icon-nav-user',
  'icon-nav-support',
  'icon-nav-pos',
  'icon-nav-support',
  'icon-nav-developer'
];
