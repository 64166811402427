import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { each } from 'lodash';
import { iconList } from './icon.constant';
import { IconComponent } from './icon.component';

@NgModule({
  imports: [CommonModule, HttpClientModule, MatIconModule],
  declarations: [IconComponent],
  exports: [IconComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class IconModule {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    each(iconList, iconName => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${iconName}.svg`)
      );
    });
  }
}
