<div class="home-hero background-{{ theme | async }} py-80">
  <div class="bg-white hero-logo px-16 py-8 position-absolute" *ngIf="(theme | async) === 'westpac'">
    <msp-icon [config]="{ name: 'icon-logo-westpac', size: 'logo-westpac' }"></msp-icon>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12 col-md-3 d-flex justify-content-center">
        <ng-container *ngIf="(theme | async) !== 'westpac'">
          <div class="hero-icon" *ngIf="(theme | async) !== 'commercebank'">
            <msp-icon [config]="themeLogo | async"></msp-icon>
          </div>

          <div class="hero-icon px-100 px-md-0 px-lg-25" *ngIf="(theme | async) === 'commercebank'">
            <img src="/assets/commerce-logo.png" alt="Commerce Bank logo" />
          </div>
        </ng-container>
      </div>
      <div class="col-12 col-md-9">
        <h1>Unified components</h1>
        <h2>Merchant. Support. Everywhere.</h2>
        <p class="type-lead mr-md-40">
          mx51's shared components provides a unified way to consistent interactions, designs, build screens and apply
          themes in more efficient way, helping business to deliver projects quickly.
        </p>
      </div>
    </div>
  </div>
</div>

<app-slides [theme]="theme | async"></app-slides>

<div class="container mt-50">
  <h2>Theming</h2>
  <p class="type-lead">
    Shared components are themeable, which makes it easier to apply global style changes to cater for different brands.
  </p>
</div>

<div class="container mt-50 mb-50">
  <h2>What we use</h2>
  <div class="row">
    <div class="d-flex col-12 col-lg-4 mb-20">
      <mat-card class="text-center flex-fill">
        <div class="row">
          <div class="highlight-img col-4 col-lg-12">
            <img src="assets/Angular.svg" alt="Angular Material" style="max-width: 13rem;" />
          </div>
          <div class="col-8 col-lg-12 text-left text-lg-center">
            <h3>Angular Material</h3>
            <div class="type-lead">
              Comprehensive, modern UI components that work across the web, mobile and desktop.
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="d-flex col-12 col-lg-4 mb-20">
      <mat-card class="text-center flex-fill">
        <div class="row">
          <div class="highlight-img col-4 col-lg-12">
            <img src="assets/bootstrap.svg" alt="Bootstrap" style="max-width: 17rem;" />
          </div>
          <div class="col-8 col-lg-12 text-left text-lg-center">
            <h3>Bootstrap</h3>
            <div class="type-lead">
              World's most popular front-end open source toolkit for developing with HTML, CSS, with responsive grid
              system.
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="d-flex col-12 col-lg-4 mb-20">
      <mat-card class="text-center flex-fill">
        <div class="row">
          <div class="highlight-img col-4 col-lg-12">
            <img src="assets/sass.svg" alt="Sass" style="max-width: 16rem;" />
          </div>
          <div class="col-8 col-lg-12 text-left text-lg-center">
            <h3>Sass</h3>
            <div class="type-lead">
              The most mature, stable, and powerful professional grade CSS extension language in the world.
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
