import { Component, Input } from '@angular/core';
import { Icon } from './icon.interface';

@Component({
  selector: 'msp-icon',
  templateUrl: './icon.component.html'
})
export class IconComponent {
  @Input() config: Icon = {
    name: 'icon-info',
    size: 'small',
    fill: 'auto'
  };

  constructor() {}
}
